import '../../styles/Webinars.scss'
import App from '../../components/App'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import MiniDice from '../../components/Mini-Dice'
import MyPagination from '../../components/MyPagination'
import React, { useEffect, useState } from 'react'
import SEO, { getSeoMedia } from '../../atoms/SEO'
import { BreadCrumbs, BreadcrumbSEO } from '../../molecules/BreadCrumbs'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link, graphql } from 'gatsby'
import { getQueryStringParams } from '../../helpers/queryParams'
import { navigate, useLocation } from '@reach/router'
import { selectDeviceSize } from '../../state/reducers/deviceSlice'
import { useScrollEffect } from '../../atoms/useScrollEffect'
import { useSelector } from 'react-redux'

const Webinars = ({ data }) => {
  const wWidth = useSelector(selectDeviceSize)
  const [stateNodes, setStateNodes] = useState([])
  const location = useLocation()
  const { nodes, totalCount } = data.allMdx
  const { page } = getQueryStringParams(location.search)
  const limit = wWidth > 1023 ? 9 : 6
  let total = Math.ceil(totalCount / limit)
  useScrollEffect(page)

  useEffect(() => {
    if (!page) {
      navigate(`/webinars/?page=1`)
    }
  }, [page])

  useEffect(() => {
    const pageNum = Number(page)

    const data = nodes.filter((webinar, i) => {
      if (i < pageNum * limit && i >= (pageNum - 1) * limit) {
        return webinar
      }
    })
    setStateNodes(data)
  }, [limit, page])

  return (
    <>
      <App>
        <SEO
          children={<BreadcrumbSEO />}
          description="Бесплатные вебинары по психологии от онлайн-сервиса YouTalk. Психологи рассказывают о психотерапии, выгорании, отношениях и подростковом возрасте."
          images={getSeoMedia([
            {
              pathname: '/img/og/webinars.png',
              alt: 'webinars'
            }
          ])}
          title="Бесплатные  вебинары по психологии и психотерапии | YouTalk"
        />
        <Header />
        <BreadCrumbs />
        <main className="main">
          <section className="materials">
            <div className="container">
              <div className="row">
                <div className="designer-col col-12">
                  <h1 className="materials__title">Вебинары</h1>
                </div>
                {Boolean(stateNodes.length) &&
                  stateNodes.map(
                    ({
                      id,
                      slug,
                      frontmatter: {
                        img,
                        title,
                        created_at,
                        description,
                        age_limit
                      }
                    }) => (
                      <div
                        key={id}
                        className="materials__col designer-col col-12 col-sm-6 col-md-4 col-lg-4"
                      >
                        <Link
                          className="materials__link materials-link"
                          to={`/${slug}`}
                        >
                          <div className="materials-link__wrap-img">
                            {Boolean(age_limit) && <MiniDice />}
                            <GatsbyImage
                              alt={created_at}
                              image={getImage(img)}
                            />
                          </div>

                          <div className="materials-link__info">
                            <h4 className="materials-link__title h4">
                              {title}
                            </h4>
                            <div className="materials-link__content b-content">
                              <p>{description}</p>
                            </div>
                            <span className="materials-link__date">
                              {created_at}
                            </span>
                          </div>
                        </Link>
                      </div>
                    )
                  )}
              </div>
              {total > 1 && (
                <div className="row">
                  <div className="designer-col col-12">
                    <MyPagination
                      className="materials__pagination"
                      id={Number(page)}
                      path="webinars"
                      total={total}
                    />
                  </div>
                </div>
              )}
            </div>
          </section>
        </main>

        <Footer />
      </App>
    </>
  )
}

export default Webinars

export const query = graphql`
  query Webinars {
    allMdx(
      sort: { order: DESC, fields: [frontmatter___created_at, slug] }
      filter: { fileAbsolutePath: { regex: "/webinars/" } }
    ) {
      nodes {
        frontmatter {
          age_limit
          created_at(formatString: "DD.MM.YYYY")
          description
          link_youtube
          title
          img {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        slug
        id
      }
      totalCount
    }
  }
`
