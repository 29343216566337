import './index.scss'
import React from 'react'

const MiniDice = () => (
  <div className="material__mini-dice">
    <div className="material__mini-dice-content">18+</div>
  </div>
)

export default MiniDice
